export interface Contest {
  contestId: string;
  name: string;
}

export interface ContestResults {
  contest: Contest;
  groups: {
    ageGroup: string;
    firstPlace: string[];
    secondPlace: string[];
    thirdPlace: string[];
    honorableMentions: string[];
    specialMentions: string[];
    others: string[];
  }[];
}

export interface HTMLElementObject {
  tag: string;
  text: string;
  children: HTMLElementObject[];
  attributes: { [key: string]: string };
}

export interface CustomSection {
  sectionName: string;
  sectionContent: string;
}

export interface FeedbackDetails {
  scores: { parameterName: string; marks: number; maxMarks: number }[];
  strengths: string;
  areaOfImprovement: string;
  comments: string;
  customSections: CustomSection[];
  childDetails: { id: string; name: string };
  contestDetails: { contestId: string; contestName: string };
}

export enum PaymentType {
  SUBSCRIPTION = "subscription",
  COMBO = "combo",
}

export type PaymentFormDetails =
  | {
      paymentType: PaymentType.SUBSCRIPTION;
      name: string;
      id: string;
      agentId: string;
      durationInMonths: number;
      amount: number;
      originalAmount?: number;
      benefits?: string;
    }
  | {
      paymentType: PaymentType.COMBO;
      id: string;
      agentId: string;
      name: string;
      discountPrice: number;
      maximumContestants: number;
    };

export interface ContestType {
  key: string;
  name: string;
}

export interface ContestQualificationLevel {
  id: string;
  levelName: string;
  levelNumber: number;
  minimumQualificationLevel: number;
}

export interface CompetitionCardDetails {
  contestId: string;
  amount: number;
  img: string;
  level: string;
  ages: string;
  submissionStartDate: string;
  submitByDate: string;
  resultDate: string;
  certificateAndGiftCardDate: string;
  name: string;
  disabled: boolean;
  isEnrolled: boolean;
  themeGuidelinesPDFLink: string;
  isLive: boolean;
  resultDeclared: boolean;
  certificateUploaded: boolean;
  isFileUploaded: boolean;
  isAFreeContest: boolean;
  contestType: ContestType;
  pinCodes?: string[];
  contestQualificationLevel?: ContestQualificationLevel;
}

export interface FormInput {
  value: string | number | boolean | File;
  isDirty: boolean;
  isValid: boolean;
  errorMessage?: string;
  required?: boolean;
}

export interface ReactSelectOption {
  value: string;
  label: string;
}

export interface ContestSelectOption {
  title: string;
  contestId: string;
  gatewayName: null | string;
  submissionStart: string;
  isLastItem: boolean;
}

export interface ContestDetailsForKPLResult {
  id: string;
  title: string;
  description: string;
  contestImage: string;
}

export interface QualifiedChildDetails {
  contest: string;
  contestId: string;
  name: string;
  age: number;
  school: string;
}

export interface KPLQualifiersSurveyFormMetadata {
  publishedOn: string;
  title: string;
  description: string;
  logo: string;
  formName: string;
  formDescription: string;
  formClosureMessage: string;
  isFormActive: boolean;
}
